<template>
  <div class="wa--pop-layout">
    <slot />
  </div>
</template>
<style lang="scss">

.wa--pop-layout {
  width: 20rem;
  margin: 0 auto;
  
  h1, h2, h3, h4, h5, h6 {
    text-align: center;
  }
}
</style>
<script>
export default {
  name: 'PopLayout'
}
</script>